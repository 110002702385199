.page-title {
  font-size: 40px;
  font-weight: 500;
  margin-top: 8px;
  margin-bottom: 8px;
}
.sub-title {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 16px;
}
.tag-line {
  font-size: 20px;
  line-height: 24px;
  font-weight: lighter;
  margin-bottom: 48px;
  margin-top: 20px;
}
