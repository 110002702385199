html,
body {
  height: 100%;
}
body {
  margin: 0;
}

main {
  position: relative;
  padding: 0 10px;
}

.img-responsive {
  width: auto;
  max-width: 100%;
}

.mat-toolbar {
  background: none;
  // height: 128px;
  .mat-toolbar-row {
    // height: 128px;
  }
  .brand {
    margin: 10px;
    max-width: 250px;
    @include phone() {
      margin-left: auto;
      margin-right: auto;
    }
    @include tablet() {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.ng-fa-icon {
  margin-right: 5px;
}

.sidenav-container {
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $white;

  @include phone {
    top: 56px;

    .mat-sidenav {
      .brand {
        display: none;
      }
    }
  }

  @include tablet {
    top: 56px;

    .mat-sidenav {
      .brand {
        display: none;
      }
    }
  }

  .brand {
    margin: 32px;
    max-width: 250px;
  }

  .mat-sidenav {
    border-right: none;
  }

  .mat-sidenav-content {
    padding: 32px;
  }
}

.menu-button {
  margin-right: $gap !important;
}

.mat-mdc-snack-bar-container {
  color: $white;
  background-color: $success;

  &.error {
    background-color: $error;
  }
}

/* avoid auto zoom on mobile browsers */
.mat-mdc-input-element {
  font-size: 14px !important;
}

.mat-mdc-option {
  font-size: 14px !important;
}

a {
  color: $primary;
  text-decoration: none;
}

strong {
  font-weight: bold;
}

.cdk-overlay-pane {
  width: auto !important;
}
