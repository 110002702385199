.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 3rem 0;
  @include phone {
    display: block;
    // A little bit of more margin so the info and filter button don't overlay any content
    margin-bottom: 8rem;
  }
  &__column {
    width: 23rem;
    margin-bottom: 4rem;
    @include phone {
      margin-left: 2rem;
      margin-right: 2rem;
      width: auto;
    }
    @include phone {
      p,
      h6 {
        font-size: 2rem;
        line-height: 2.4rem;
      }
    }
  }
  &__heading {
    color: $text-light;
    min-height: 2.5rem;
    padding: 0 0.5rem;
    margin-bottom: 1.5rem;
    border-bottom: solid 1px $border-grey;
  }
  &__content {
    color: $text-light;
    padding: 0 0.5rem;
    line-height: 1.8rem;
    strong {
      font-weight: 500;
    }
    a {
      color: $primary;
      text-decoration: none;
    }
    @include phone {
      font-size: 2rem;
      line-height: 2.4rem;
    }
    &__assistance {
      color: $primary;
    }
  }
  &__list {
    list-style-type: none;

    &__item {
      margin-bottom: 1rem;
    }
  }
  &__link {
    text-decoration: none;
    color: $text-light;
  }
}
