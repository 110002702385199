.split {
  display: flex;
  &__cell {
    flex-basis: 1px;
    flex-grow: 1;
    flex-shrink: 1;
    margin-left: $gap;
    margin-right: $gap;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  @include phone {
    flex-direction: column;
    &__cell {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
