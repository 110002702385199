body .cdk-overlay-pane {
  max-width: 100vw !important;
  max-height: 100vh;
}
body .mat-mdc-dialog-container {
  background-color: transparent;
  box-shadow: none;
  border: none;
  @include phone() {
    padding: 0;
    .sx-content-card {
      width: 100%;
      height: 100%;
    }
  }
}
