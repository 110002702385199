.mat-mdc-form-field {
  width: 100%;

  .mdc-text-field {
    padding-left: 0;
    padding-right: 0;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background: none;
  }

  .mdc-text-field--filled.mdc-text-field--disabled {
    background: none;
  }

  mat-label,
  .mat-mdc-select-value-text {
    font-size: 14px !important;
  }

  &.mat-focused .mat-mdc-form-field-focus-overlay {
    opacity: 0 !important;
  }
  .mat-mdc-text-field-wrapper:hover .mat-mdc-form-field-focus-overlay {
    opacity: 0 !important;
  }
}

.label--required {
  color: $error;
}
