.validation-message {
  font-size: 1.2rem;
  font-weight: 500;
  color: $error;
  margin-bottom: 1.2rem;
  margin-top: -1.2rem;
  &--margin-less {
    margin-top: 0;
    margin-bottom: 0;
  }
}
