.payment-item {
  width: 43rem;
  @include phone {
    width: auto;
  }
  @include tablet {
    width: auto;
  }
  display: flex;
  font-size: 1.8rem;
  font-weight: 300;
  color: $text-normal;
  margin-top: $quarter-gap;
  margin-bottom: $quarter-gap;
  &__position {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 10rem;
  }
  &__currency {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 4rem;
  }
  &__amount {
    flex-grow: 0;
    flex-shrink: 0;
    text-align: right;
    flex-basis: 8rem;
  }

  &--total {
    font-weight: 500;
    padding-top: $gap;
    border-top: solid 1px $border-grey;
  }
}
