.sidebar {
  display: block;
  &__section {
    margin-left: -$content-card-outer-padding;
    margin-right: -$content-card-outer-padding;
    border-bottom: solid 1px $border-grey;
    &:last-child {
      border-bottom: none;
    }
  }
  &__section-content {
    margin: 2.3rem;
  }
  &__title {
    font-size: 2.4rem;
    letter-spacing: 0;
    font-weight: 300;
    color: $text-normal;
    opacity: 1;
    display: flex;
    margin-left: 1rem;
    margin-right: 3.2rem;
  }
  &__title-icon {
    flex-basis: 3.2rem;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 1.6rem;
    color: $primary;
  }
  &__title-text {
    flex-basis: 3.2rem;
    flex-grow: 1;
    flex-shrink: 1;
  }
  &__link {
    text-decoration: none;
  }
  &__section-content-part {
    margin-bottom: 2.5rem;
    font-weight: 300;
    font-size: 1.6rem;
    line-height: 2.5rem;
  }
  &__section-content__assistance-button__wrapper {
    &:not(:last-of-type) {
      margin-bottom: 1.1rem;
    }
  }
}
