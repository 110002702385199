.no-exams-message {
  font-size: 2.4rem;
  letter-spacing: 0;
  font-weight: 300;
  color: $text-normal;
  opacity: 1;
  display: flex;
  margin-left: 1rem;
  margin-right: 3.2rem;
  &__text {
    display: inline-block;
    margin-left: $gap;
  }
}
