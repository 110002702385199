$gap: 16px;
$half-gap: 8px;
$quarter-gap: 8px;
$double-gap: 32px;
$quadruple-gap: 64px;

// Custom colors
$primary: #811330;
$error: #e2354a;
$success: #3babef;
$white: #fff;
$text-light: #989898;
$light-grey: #989898;
$link-hover: #f7f7f7;
$gold: #a89344;
$border-grey: #d1d1d1;

$content-card-outer-padding: 10px;
$content-card-margin: 5px;

$text-normal: #232323;
