.content-card {
  background: #f8f8f8 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 1rem;
  opacity: 1;
  padding: $content-card-outer-padding;
  margin: $content-card-margin;
  display: block;

  &__inner {
    display: block;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #d1d1d1;
    border-radius: 0.5rem;
    opacity: 1;
    margin-bottom: 1rem;
    padding: 1rem;
    &:last-child {
      // margin-bottom: 0px;
    }
  }
  &--long-foot {
    padding-bottom: 6.4rem;
  }

  &__section {
    display: block;
    margin-left: -$content-card-outer-padding;
    margin-right: -$content-card-outer-padding;
    border-bottom: solid 1px $border-grey;
    &:last-child {
      border-bottom: none;
    }
    &--scrollable {
      overflow-y: auto;
    }
  }
  &__section-content {
    margin: 2.3rem;
  }

  &__title {
    width: 100%;
    font-size: 2.4rem;
    letter-spacing: 0;
    font-weight: 300;
    color: $text-normal;
    opacity: 1;
    display: flex;
  }
  &__title-icon {
    flex-basis: 3.2rem;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 0.8rem;
    color: $primary;
  }
  &__title-text {
    flex-basis: 3.2rem;
    flex-grow: 1;
    flex-shrink: 1;
  }
  &__sub-title {
    display: block;
    width: 100%;
    font-size: 1.8rem;
    line-height: 2.4rem;
    letter-spacing: 0;
    font-weight: 300;
    color: $text-normal;
    opacity: 1;
    margin-top: $gap;
  }
  &__section-title {
    width: 100%;
    font-size: 2.4rem;
    letter-spacing: 0;
    font-weight: 300;
    color: $text-normal;
    opacity: 1;
    display: flex;
    border-bottom: solid 1px $border-grey;
    padding-bottom: $half-gap;
    margin-bottom: $gap;
  }

  .mat-mdc-unelevated-button {
    font-size: 1.6rem;
    line-height: 4rem;
  }
}
