.padding {
  &-left {
    padding-left: $gap;
  }
  &-right {
    padding-right: $gap;
  }
  &-top {
    padding-top: $gap;
  }
  &-bottom {
    padding-bottom: $gap;
  }
}
.margin {
  &-left {
    margin-left: $gap;
  }
  &-right {
    margin-right: $gap;
  }
  &-top {
    margin-top: $gap;
    &--double {
      margin-top: $double-gap;
    }
    &--quadruple {
      margin-top: $quadruple-gap;
    }
  }
  &-bottom {
    margin-bottom: $gap;
    &--half {
      margin-bottom: $half-gap;
    }
  }
}
