.dialog {
  display: block !important;
  max-width: 45rem;
  &__divider {
    margin-top: $double-gap !important;
  }
  &__actions {
    text-align: right;
  }

  .content-card {
    margin: 0;
  }
}
