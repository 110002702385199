html {
  font-size: 62.5%; /* 62.5% of 16px = 10px */

  @include phone {
    font-size: 52%;
  }
}
body {
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  font-size: 1.4rem; /* =14px */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Raleway, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 300;
  margin: 2rem 0 0.5rem 0;
}

h1 {
  font-size: 4rem; /* =40px */
}

h2 {
  font-size: 3rem; /* =30px */
}

h3 {
  font-size: 2.6rem; /* =26px */
}

h4 {
  font-size: 2.3rem; /* =23px */
}

h5 {
  font-size: 2rem; /* =20px */
}

h6 {
  font-size: 1.7rem; /* =17px */
}

p {
  font-size: 1.6rem; /* =16px */
  font-weight: 300;
  margin: 1rem 0;
  line-height: 2.5rem;
  //overflow-wrap: break-word;
  //word-wrap: break-word;
  //-webkit-hyphens: auto;
  //-ms-hyphens: auto;
  //-moz-hyphens: auto;
  //hyphens: auto;
}
