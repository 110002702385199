.registration-process {
  &__back-link {
    margin-left: 3rem;
    font-size: 1.6rem;
    font-weight: 300;
    color: $text-normal;
    a {
      color: $text-normal;
      text-decoration: none;
    }
  }
  &__action {
    text-align: right;
  }
  .exam {
    margin-bottom: 0;
  }
  &__status-message {
    font-size: 2rem;
    color: $primary;
    padding: $gap;
  }
}

.input-suffix {
  display: flex;

  &__date-picker {
    font-size: 16px;
  }

  &__info {
    font-size: 12px;
    padding-top: 1px;
    color: $primary;

    &__icon {
      &--vertical-center {
        vertical-align: middle !important;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }
}
