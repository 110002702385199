.flow {
  display: flex;
  &--vertical {
    flex-direction: row;
  }
  &__fixed-cell {
    flex-basis: auto;
    flex-grow: 0;
    flex-shrink: 0;
  }
  &__resizing-cell {
    flex-basis: auto;
    flex-grow: 1;
    flex-shrink: 1;
  }
}
