body .exam {
  display: block;
  margin-bottom: 27px;
  padding-bottom: 8px;
  background-color: #f8f8f8;
  &__header {
    background: #eeeeee 0% 0% no-repeat padding-box;
    display: flex;
    font-size: 2.2rem;
    font-weight: 400;
    padding: 8px;
    @include phone {
      flex-direction: column;
      font-size: 2rem;
    }
  }
  &__title {
    flex-basis: 3px;
    flex-grow: 3;
    flex-shrink: 3;
    color: $text-normal;
  }
  &__title_aside {
    flex-basis: 1px;
    flex-grow: 2;
    flex-shrink: 2;
    text-align: right;
    @include phone {
      margin-top: $half-gap;
      text-align: left;
    }
  }
  &__content-row {
    padding: 8px;
    margin-top: 8px;
    margin-bottom: 8px;
    display: flex;
    margin-left: 24px;
    margin-right: 24px;

    @include phone {
      flex-wrap: wrap;
      margin: 0;
      padding: 0 8px;
    }
  }
  &__content-row-item {
    flex-basis: 1px;
    flex-grow: 1;
    flex-shrink: 1;

    @include phone {
      flex-basis: 50%;
      padding: 8px 0;
    }

    &__stack {
      display: flex;
      flex-direction: column;
    }
  }
  &__content-row-item--centered {
    text-align: center;
  }
  &__content-row-item--centered-on-tablet-and-up,
  &__content-row-item-value--centered-on-tablet-and-up {
    @include tablet {
      text-align: center !important;
      display: inline !important;
    }
    @include desktop {
      text-align: center !important;
      display: inline !important;
    }
  }
  &__content-row-item-label {
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #989898;
    display: block;
    margin-bottom: 8px;
  }
  &__content-row-item-value {
    display: flex;
    font-size: 1.6rem;
    font-weight: 300;
    color: $text-normal;
    &--wrap {
      flex-wrap: wrap;
    }

    .ng-fa-icon {
      color: $primary;
      fill: $primary;
    }
  }
  &__product-button {
    min-width: 80px;
    margin: 0 auto 6px;
  }
  &__product-label {
    display: inline-block;
    padding-left: $double-gap;
    padding-right: $double-gap;
    border-radius: 4px;
    line-height: 24px;
    font-weight: 400;
    font-size: 12px;
    text-transform: uppercase;
    margin: 0 auto;
    &--primary {
      color: white;
      background-color: $primary;
    }
    &--gold {
      color: white;
      background-color: $gold;
    }
  }
}
