.info-message {
  display: flex;
  align-items: center;
  &__content {
    flex: 1 1 auto;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  &__icon {
    flex: 0 0 auto;
    align-self: baseline;
    mat-icon {
      display: block;
    }
  }
  &--red {
    color: #fff;
    background-color: $primary;
    border-radius: 1.5rem;
  }
}
