.mat-mdc-unelevated-button.mat-flat-button--short {
  line-height: 24px;
  font-weight: 400;
  font-size: 12px;
}
.mat-mdc-unelevated-button.mat-flat-button--gold {
  color: white;
  background-color: $gold;
  text-transform: uppercase;
}
.mat-mdc-unelevated-button.mat-flat-button--primary {
  color: white;
  background-color: $primary;
  text-transform: uppercase;
}
