$filter-width: 100%;

.filter {
  padding: 10px;
  &__heading {
    @include phone() {
      display: none;
    }
    display: flex;
    font-weight: 300;
    font-size: 24px;
    align-items: baseline;
    .ng-fa-icon {
      color: $primary;
      fill: $primary;
    }
    &__spacer {
      flex: 1 1 auto;
    }
  }
  &__filters {
    margin-top: 0;
    &:first-child {
      margin-top: $gap;
    }
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    width: 100%;
    justify-content: space-around;
    @include phone() {
      display: block;
    }
  }
  &__filter {
    flex-grow: 1;
    flex-basis: 10px;
    margin-left: $half-gap;
    margin-right: $half-gap;
    .mat-mdc-form-field-infix {
      width: $filter-width;
    }
    .mat-mdc-select {
      height: 1.7rem;
      width: $filter-width;
    }
    .mat-mdc-input-element {
      width: $filter-width;
    }
  }
  &__permalink,
  &__permalink-mobile {
    text-color: $text-light;
    font-size: 1.2rem;
  }
  &__permalink {
    margin-left: $double-gap;
  }
}
