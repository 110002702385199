.container {
  display: flex;
  flex-wrap: wrap;

  &--centered {
    justify-content: center;
    align-items: center;
  }

  &--padded {
    > .container__one-cell,
    > .container__two-cells {
      padding-left: $gap;
      padding-right: $gap;
    }
  }

  &__one-cell {
    flex-basis: 1px;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;

    &__centered {
      align-self: center;
    }

    @include phone {
      flex-basis: unset;
    }
  }

  &__two-cells {
    flex-basis: 2px;
    flex-grow: 2;
    flex-shrink: 2;
    overflow: hidden;
  }

  &__title {
    font-size: 22px;
    font-weight: 500;

    &--text-center {
      text-align: center;
    }
  }
}
