.important-notice {
  margin: 10px;
  padding: 18px;
  border-radius: 5px;
  color: $white;
  background-color: $error;

  a {
    color: $white;
    text-decoration: underline;
  }
}
