$app-width: 1196px;
.root {
  display: block;
  max-width: $app-width;
  margin-left: auto;
  margin-right: auto;

  &__header {
    position: fixed;
    width: 100%;
    max-width: $app-width;
    z-index: 1000;
    @include phone {
      position: absolute;
    }
    &--hidden {
      visibility: hidden;
    }
  }

  &__brand {
    .logo {
      max-height: 10rem;
    }
  }

  .mat-toolbar {
    position: relative;
    padding-top: 1rem;
    background-color: transparent;
    background-color: #fff;
    top: 0;
  }
  .mat-toolbar-row {
    height: auto;
  }
  &__upper-row.mat-toolbar-row {
    border-bottom: solid 2px $primary;
    padding-bottom: $gap;
  }
  &__lower-row.mat-toolbar-row {
  }

  &__spacer {
    flex-grow: 1;
  }
  &__language {
    font-size: 14px;
    font-weight: 400;

    &__button {
      color: $text-normal;
      text-decoration: none;
      &.active {
        color: $primary;
      }
    }
  }
  &__main {
    display: block;
    z-index: 500;
    padding-bottom: 100px;
    border-bottom: solid 2px $primary;
    margin-bottom: 10px;
  }
  &__footer {
    position: relative;
  }
}
