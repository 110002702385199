.box {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 18px;
  line-height: 24px;
  font-weight: 300;
  padding: 18px;
  margin: 10px;
  border-radius: 5px;
  &--full-height {
    height: calc(100% - 10px);
  }
  &__title {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 16px;
  }
  &--red {
    color: #fff;
    background-color: $primary;
  }
  &--gold {
    color: #fff;
    background-color: $gold;
  }
  &--bordered {
    border: 1px solid $border-grey;
  }
  p {
    margin-bottom: $gap;
  }
  li {
    list-style-type: disc;
    margin-left: $gap;
    line-height: 120%;
  }

  &__link {
    &--bold > a {
      font-weight: 500;
    }
  }
}
