.mat-expansion-panel {
  .mat-expansion-panel-content {
    font-size: inherit;
  }
  &--frameless {
    border: none;
    box-shadow: none !important;
    .mat-expansion-panel-body {
      padding: 0;
    }
  }
}
