.mobile-only {
  display: none;
  @include phone {
    display: block;
  }
  &__fab-button-left {
    position: fixed !important;
    bottom: 1rem;
    left: 1rem;
  }
  &__fab-button-right {
    position: fixed !important;
    bottom: 1rem;
    right: 1rem;
  }
}
